// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-template-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-feature-template-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/templates/featureTemplate.js" /* webpackChunkName: "component---src-templates-feature-template-js" */),
  "component---src-templates-help-template-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/templates/helpTemplate.js" /* webpackChunkName: "component---src-templates-help-template-js" */),
  "component---src-templates-help-kb-template-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/templates/help/kbTemplate.js" /* webpackChunkName: "component---src-templates-help-kb-template-js" */),
  "component---src-pages-404-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beta-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-features-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-help-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-help-kb-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/help/kb.js" /* webpackChunkName: "component---src-pages-help-kb-js" */),
  "component---src-pages-index-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-oauth-twitch-beta-callback-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/oauth/twitch/beta/callback.js" /* webpackChunkName: "component---src-pages-oauth-twitch-beta-callback-js" */),
  "component---src-pages-pricing-js": () => import("/Volumes/Untitled/repos/streamparrots-marketing-site/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

