/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'react-md/dist/react-md.indigo-pink.min.css'
import './src/components/layout.css'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!window.ackeeTrackerInstance && window.ackeeTracker) {
    window.ackeeTrackerInstance = window.ackeeTracker.create(
      {
        server: 'https://analytics.streamparrot.com',
        domainId: 'f9cc2623-f7a5-4683-b552-f7a0b7adca16',
      },
      {
        ignoreLocalhost: true,
      }
    )
  }

  if (window.ackeeTrackerInstance) {
    window.ackeeTrackerInstance.record()
  }
}
